import React from 'react';
import {Link} from 'gatsby';
import {Helmet} from "react-helmet";

const PageNotFound = () => (

	<div>
		Page not found. <Link to="/">Home</Link>
	</div>
);

export default PageNotFound;
